import React, { Fragment, useState } from 'react';
import './RightSummary.scss';
import StopOct from './stop.svg';
import Dialog from './StopDialog';
import { timeMath } from '../../scripts/dateTimeFormatter';
import ProgressBar from './ProgressBar/ProgressBar.jsx';

const RightSummary = ({ buildInfoData }) => {
  const {
    machineName, buildFile, machineState, buildTime, heatingStateTime,
  } = buildInfoData;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const timeStamp = new Date(latestTime).toLocaleString();
  // let nowTimeStamp = new Date()

  // setInterval(() => {
  //   nowTimeStamp.setSeconds( nowTimeStamp.getSeconds() + 2 );
  // }, 2000)

  // TODO: refactor - this runTime is also calculated in LeftSummary.
  const runTime = heatingStateTime
    ? timeMath(Math.floor((new Date()).getTime() / 1000), Math.floor(heatingStateTime), 'subtract')
    : '???';
  const buildPercentage = buildTime && runTime && Math.floor((runTime / buildTime) * 100);
  let formattedBuildPercentage;

  if (!buildPercentage) {
    formattedBuildPercentage = 0;
  } else {
    formattedBuildPercentage = buildPercentage > 100 ? 100 : buildPercentage;
  }

  return (
    <>
      <Dialog
        isDialogOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
      />
      <div className="RightSummary">
        <div className="leftContainer">
          <div className="orderName">{buildFile}</div>
          <span className={machineState === 'Offline' ? 'machineName offline' : 'machineName'} data-testid="machine-name">
            {machineName}
            {' '}
            -
            {' '}
          </span>
          <span className={`status status-${machineState}`} data-testid="Machine-Status">{machineState}</span>
        </div>
        <div className="rightContainer">
          <ProgressBar value={formattedBuildPercentage} raw={buildPercentage} buildData={buildInfoData} />
          <div className="stopContainer" data-testid="stop-container">
            <img src={StopOct} alt="STOP" onClick={() => setIsDialogOpen(true)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSummary;
