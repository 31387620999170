import React, { useState, Fragment } from 'react';
// import WhiteCamera from './Camera Image.png';
import GreyCamera from './Camera Image, Grey.png';

import './Camera.scss';

import CameraDialog from './CameraDialog';

const Camera = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <CameraDialog
        isDialogOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
      />
      <div className="Camera Camera-Inactive" onClick={() => {}}>
        <img src={GreyCamera} alt="Loading Camera Feed" className="Image" />
      </div>
    </>
  );
};

export default Camera;
