import React, { useRef, useEffect } from 'react';
import { select, selectAll } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';
import './gridlines.scss';

const Gridlines = ({
  type, scale, height, width, tickValues, timeRange,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    renderAxis();
  }, []);

  useEffect(() => {
    updateAxis();
  }, [scale, height, width, tickValues, timeRange]);

  const isXMajorTick = (val) => {
    if (timeRange === 'small') {
      return val % 60 === 0;
    } if (timeRange === 'medium') {
      return val % 600 === 0;
    }
    return val % 3600 === 0;
  };

  const isYMajorTick = (val) => val % 50 === 0;

  const getMajorTickValues = () => tickValues.filter((tickVal) => {
    if (type === 'x') {
      return isXMajorTick(tickVal);
    }
    return isYMajorTick(tickVal);
  });

  const getMinorTickValues = () => tickValues.filter((tickVal) => {
    if (type === 'x') {
      return !isXMajorTick(tickVal);
    }
    return !isYMajorTick(tickVal);
  });

  const renderAxis = () => {
    const node = ref.current;

    const majorTickValues = getMajorTickValues();
    const minorTickValues = getMinorTickValues();

    if (type === 'x') {
      const makeXGridlines = () => axisBottom(scale);

      select(node)
        .append('g')
        .attr('class', 'x-major-grid')
        .attr('transform', `translate(0, ${height})`)
        .call(makeXGridlines()
          .tickValues(majorTickValues)
          .tickSize(-height, 0, 0)
          .tickFormat('')
          .tickSizeOuter(0));

      select(node)
        .append('g')
        .attr('class', 'x-minor-grid')
        .attr('transform', `translate(0, ${height})`)
        .call(makeXGridlines()
          .tickValues(minorTickValues)
          .tickSize(-height, 0, 0)
          .tickFormat('')
          .tickSizeOuter(0));
    } else {
      const makeYGridlines = () => axisLeft(scale);

      select(node)
        .append('g')
        .attr('class', 'y-major-grid')
        .call(makeYGridlines()
          .tickValues(majorTickValues)
          .tickSize(-width, 0, 0)
          .tickFormat('')
          .tickSizeOuter(3));

      select(node)
        .append('g')
        .attr('class', 'y-minor-grid')
        .call(makeYGridlines()
          .tickValues(minorTickValues)
          .tickSize(-width, 0, 0)
          .tickFormat('')
          .tickSizeOuter(3));
    }
  };

  const updateAxis = () => {
    const majorTickValues = getMajorTickValues();
    const minorTickValues = getMinorTickValues();

    if (type === 'x') {
      const axis = axisBottom(scale);

      selectAll('.x-major-grid')
        .call(
          axis
            .tickValues(majorTickValues)
            .tickSize(-height, 0, 0)
            .tickFormat('')
            .tickSizeOuter(3),
        );

      selectAll('.x-minor-grid')
        .call(
          axis
            .tickValues(minorTickValues)
            .tickSize(-height, 0, 0)
            .tickFormat('')
            .tickSizeOuter(0),
        );
    } else {
      const axis = axisLeft(scale);

      selectAll('.y-major-grid')
        .call(
          axis
            .tickValues(majorTickValues)
            .tickSize(-width, 0, 0)
            .tickFormat('')
            .tickSizeOuter(3),
        );

      selectAll('.y-minor-grid')
        .call(
          axis
            .tickValues(minorTickValues)
            .tickSize(-width, 0, 0)
            .tickFormat('')
            .tickSizeOuter(3),
        );
    }
  };

  return (
    <g
      ref={ref}
      className="grid-group"
    />
  );
};

export default Gridlines;
