import React, { useState, useEffect } from 'react';
import './MachineSelection.scss';
import MachineSelect from './MachineSelect';
import FilterButtons from '../filterButtons/FilterButtons.jsx';
import Power from './Power.png';
import PowerCooling from './Power, Cooling.png';
import PowerDisconnected from './Power, Disconnected.png';
import PowerHeating from './Power, Heating.png';
import PowerPrinting from './Power, Printing.png';
import { useSwipeable, Swipeable } from 'react-swipeable'
import { filter } from 'd3';

export const calculateStatus = (sensors, ignoreAmbient) => Object.keys(sensors).reduce((accu, sensor) => {
  if (accu === 'death' || (sensor === 'ambient' && ignoreAmbient)) {
    return accu;
  } if (accu === 'warn') {
    return sensors[sensor].severity === 'death' ? 'death' : accu;
  }
  return sensors[sensor].severity;
}, 'ok');

export const calculatePastStatus = (sensors, ignoreAmbient) => Object.keys(sensors).reduce((accu, sensor) => {
  if (accu === 'death' || (sensor === 'ambient' && ignoreAmbient)) {
    return accu;
  }
  const highSeverity = sensors[sensor].high.severity;
  const lowSeverity = sensors[sensor].low.severity;

  if (highSeverity === 'death' || lowSeverity === 'death') {
    return 'death';
  } if (
    accu === 'warn'
      || highSeverity === 'warn'
      || lowSeverity === 'warn'
  ) {
    return 'warn';
  }
  return 'ok';
}, 'ok');
const MachineSelection = ({
  changeMachine,
  machines,
  currentSensorStatus,
  minMaxData,
  buildInfoData,
  ignoreAmbient,
  currentMachine,
  atlasMachineData,
  keyValue,
  setCurrentMachine,
}) => {
  const [filterButtonsAreShown, setFilterButtonsAreShown] = useState(false);
  const initialFilterState = {
    Offline: false, Cooling: false, Heating: false, Printing: false, Online: false,
  };
  const [filterBy, setFilterBy] = useState({...initialFilterState});

  useEffect(() => {
    const machineIndex = generateFilteredMachines().indexOf(currentMachine)
    if (keyValue.ctrl) {
      if (keyValue.keyValue === 'ArrowUp') {
        // this is the up arrow key
        if (machineIndex !== 0) {
          setCurrentMachine(generateFilteredMachines()[machineIndex - 1]);
        }
      } else if (keyValue.keyValue === 'ArrowDown') {
        // this is down Arrow key
        if (machineIndex !== generateFilteredMachines().length - 1) {
          setCurrentMachine(generateFilteredMachines()[machineIndex + 1]);
        }
      }
    }
  }, [keyValue]);

  const filterButtons = [
    {
      button: PowerDisconnected, action: () => filterButtonHandler('Offline'), dotRef: 'Offline',
    },
    {
      button: Power, action: () => filterButtonHandler('Online'), dotRef: 'Online',
    },
    {
      button: PowerHeating, action: () => filterButtonHandler('Heating'), dotRef: 'Heating',
    },
    {
      button: PowerPrinting, action: () => filterButtonHandler('Printing'), dotRef: 'Printing',
    },
    {
      button: PowerCooling, action: () => filterButtonHandler('Cooling'), dotRef: 'Cooling',
    },
  ];

  const filterButtonHandler = (action) => {
    switch (action) {
      case 'Online':
        setFilterBy({
          ...filterBy, All: false, Online: !filterBy[action]
        });
        break;
      case 'Offline':
        setFilterBy({ ...filterBy, Offline: !filterBy[action] });
        break;
      case 'Cooling':
        setFilterBy({ ...filterBy, Cooling: !filterBy[action] });
        break;
      case 'Heating':
        setFilterBy({ ...filterBy, Heating: !filterBy[action] });
        break;
      case 'Printing':
        setFilterBy({ ...filterBy, Printing: !filterBy[action] });
        break;
      default:
        setFilterBy({...initialFilterState});
        break;
    }
  };

  const generateFilteredMachines = () => {
    let currentMachines = [];
    if (filterBy.Cooling === true) {
      currentMachines = [...currentMachines, ...machines.filter((machine) => atlasMachineData.buildInfoData[machine].machineState === 'Cooling')];
    }
    if (filterBy.Heating === true) {
      currentMachines = [...currentMachines, ...machines.filter((machine) => atlasMachineData.buildInfoData[machine].machineState === 'Heating')];
    }
    if (filterBy.Printing === true) {
      currentMachines = [...currentMachines, ...machines.filter((machine) => atlasMachineData.buildInfoData[machine].machineState === 'Printing')];
    }
    if (filterBy.Online === true) {
      currentMachines = [...machines.filter((machine) => atlasMachineData.buildInfoData[machine].machineState !== 'Offline')];
    };
    if (filterBy.Offline === true) {
      currentMachines = [...currentMachines, ...machines.filter((machine) => atlasMachineData.buildInfoData[machine].machineState === 'Offline')];
    }
    // checks if all the filters are off, if so sets it back to default of no filters
    if (Object.keys(filterBy).every((k) => !filterBy[k]) === true) {
      return machines
    }
    return currentMachines;
  };

  const handlers = useSwipeable({ onSwiped: ({dir}) => {
    if (dir === 'Left') {
      setFilterButtonsAreShown(!filterButtonsAreShown)
    }
  }});

  return (
    <div
      className="machineSelectionContainer"
      onMouseEnter={() => setFilterButtonsAreShown(true)}
      onMouseLeave={() => setFilterButtonsAreShown(false)}
      {...handlers}
    >
      <FilterButtons show={filterButtonsAreShown} filterButtons={filterButtons} filterBy={filterBy} />
      <div
        className="machineSelection"
      >
        {generateFilteredMachines().map((machine) => (
          <MachineSelect
            key={machine}
            changeMachine={changeMachine}
            machine={machine}
            machineState={buildInfoData[machine].machineState}
            currentStatus={
          currentSensorStatus
          && currentSensorStatus[machine]
          && calculateStatus(currentSensorStatus[machine], ignoreAmbient)
        }
            pastStatus={
            minMaxData
            && minMaxData[machine]
            && calculatePastStatus(minMaxData[machine], ignoreAmbient)
        }
            currentMachine={currentMachine}
          />
        ))}
      </div>
    </div>
  );
};

export default MachineSelection;
