import React, { useRef, useEffect } from 'react';
import { select } from 'd3-selection';
// import { transition } from 'd3-transition';

const Line = ({
  type, data, lineGenerator, idx = '',
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const node = ref.current;

    const initialData = data.map((d) => ({
      name: d.timeStamp,
      value: d.value,
    }));

    if (type === 'data') {
      select(node)
        .append('path')
        .datum(initialData)
        .attr('id', `${type}-line`)
        .attr('stroke', '#00FFFF')
        .attr('stroke-width', 1.5)
        .attr('fill', 'none')
        .attr('d', lineGenerator);
    } else {
      let strokeColor;

      switch (type) {
        case 'target':
          strokeColor = 'green';
          break;
        case 'printing':
          strokeColor = '#FF5A00';
          break;
        case 'cooling':
          strokeColor = '#86D6D8';
          break;
        case 'current':
          strokeColor = '#00FFFF';
          break;
        case 'missing':
          strokeColor = 'red';
          break;
        default:
          strokeColor = 'orange';
      }
      select(node)
        .append('path')
        .datum(initialData)
        .attr('id', `${type}${idx}-line`)
        .style('stroke-dasharray', ('5, 5'))
        .attr('stroke', strokeColor)
        .attr('stroke-width', 1.0)
        .attr('fill', 'none')
        .attr('d', lineGenerator);
    }

    updateChart();
  }, []);

  useEffect(() => {
    updateChart();
  }, [data, lineGenerator]);

  const updateChart = () => {
    // const t = transition().duration(1000);

    const line = select(`#${type}${idx}-line`);

    line
      .datum(data)
      // .transition(t)
      .attr('d', lineGenerator);
  };

  return <g className="line-group" ref={ref} />;
};

export default Line;
