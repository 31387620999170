import React from 'react';
import { scaleLinear } from 'd3-scale';
import { line, curveMonotoneX } from 'd3-shape';
import XYAxis from './Axis/xy-axis';
import Line from './Line/Line';
import Gridlines from './Grid/Gridlines';
import './Graph.scss';

const Graph = ({
  data, missingData = [], title, upperThreshold, lowerThreshold, globalMin, globalMax, target, printingStateTime, coolingStateTime, parentWidth, parentHeight,
}) => {
  const margins = {
    top: 40,
    right: 20,
    bottom: 40,
    left: 50,
  };

  const width = parentWidth - margins.left - margins.right;
  const height = parentHeight - margins.top - margins.bottom;

  const yTicks = 6;

  const earliestTimeStamp = data[0].timeStamp;
  const latestTimeStamp = data[data.length - 1].timeStamp;
  const xBoundValue = latestTimeStamp * 1.02;

  const xTickValues = [0]; let timeRange; let
    timeInterval;

  if (latestTimeStamp <= 600) {
    timeRange = 'small';
    timeInterval = 30;
  } else if (latestTimeStamp <= 3600) {
    timeRange = 'medium';
    timeInterval = 60;
  } else {
    timeRange = 'large';
    timeInterval = 900;
  }

  while (xBoundValue > xTickValues[xTickValues.length - 1]) {
    const nextTickValue = xTickValues[xTickValues.length - 1] + timeInterval;

    if (nextTickValue > xBoundValue) {
      xTickValues.push(xBoundValue);
    } else {
      xTickValues.push(nextTickValue);
    }
  }

  const xScale = scaleLinear()
    .domain([0, xTickValues[xTickValues.length - 1]])
    .range([0, width]);

  const temperatureValues = data.map((d) => d.value);
  const latestTemperatureValue = temperatureValues[temperatureValues.length - 1];

  const yTickValues = [0];
  const maxTemperatureVal = Math.max(...temperatureValues);
  const yBoundValue = maxTemperatureVal * 1.10;

  while (yBoundValue > yTickValues[yTickValues.length - 1]) {
    const nextTickValue = yTickValues[yTickValues.length - 1] + 10;

    if (nextTickValue > yBoundValue) {
      yTickValues.push(yBoundValue);
    } else {
      yTickValues.push(nextTickValue);
    }
  }

  const yScale = scaleLinear()
    .domain([0, yTickValues[yTickValues.length - 1]])
    .range([height, 0]);

  const lineGenerator = line()
    .x((d) => xScale(d.timeStamp))
    .y((d) => yScale(d.value))
    .curve(curveMonotoneX);

  let upperLineData; let lowerLineData; let targetLineData; let printingStateLineData; let coolingStateLineData; let currentLineData; let
    missingLinesData;

  upperThreshold && (upperLineData = [
    { value: upperThreshold, timeStamp: earliestTimeStamp },
    { value: upperThreshold, timeStamp: xBoundValue },
  ]);

  lowerThreshold && (lowerLineData = [
    { value: lowerThreshold, timeStamp: earliestTimeStamp },
    { value: lowerThreshold, timeStamp: xBoundValue },
  ]);

  target && (targetLineData = [
    { value: target, timeStamp: earliestTimeStamp },
    { value: target, timeStamp: xBoundValue },
  ]);

  printingStateTime && (printingStateLineData = [
    { value: 0, timeStamp: printingStateTime },
    { value: yBoundValue, timeStamp: printingStateTime },
  ]);

  coolingStateTime && (coolingStateLineData = [
    { value: 0, timeStamp: coolingStateTime },
    { value: yBoundValue, timeStamp: coolingStateTime },
  ]);

  currentLineData = [
    { value: latestTemperatureValue, timeStamp: latestTimeStamp },
    { value: latestTemperatureValue, timeStamp: xBoundValue },
  ];

  missingData.length && (missingLinesData = missingData.map((point) => [
    { value: 0, timeStamp: point.timeStamp },
    { value: yBoundValue, timeStamp: point.timeStamp },
  ]));

  const globalXposition = width * 0.9;

  return (
    <div className="Graph">
      <svg
        className="lineChartSvg"
        width={width + margins.left + margins.right}
        height={height + margins.top + margins.bottom}
      >
        <g
          transform={`translate(${margins.left}, ${margins.top})`}
        >
          <text
            className="graph-title"
            x={width / 2}
            y={0 - (margins.top / 2)}
          >
            {title}
          </text>
          {globalMax && (
          <text
            className="global"
            x={globalXposition}
            y={height * 0.25}
          >
            Highest:
            {' '}
            {globalMax === -Infinity ? 'N/A' : globalMax}
          </text>
          )}
          <text className="global" x={globalXposition} y={height * 0.5}>
            Current:
            {' '}
            {latestTemperatureValue}
          </text>
          {globalMin && (
          <text
            className="global"
            x={globalXposition}
            y={height * 0.75}
          >
            Lowest:
            {' '}
            {globalMin === Infinity ? 'N/A' : globalMin}
          </text>
          )}
          <Gridlines
            type="x"
            scale={xScale}
            width={0}
            height={height}
            tickValues={xTickValues}
            timeRange={timeRange}
          />
          <Gridlines
            type="y"
            scale={yScale}
            width={width}
            height={0}
            tickValues={yTickValues}
          />
          <XYAxis {...{
            xScale,
            yScale,
            height,
            width,
            yTicks,
            margins,
            xTickValues,
            yTickValues,
            timeRange,
          }}
          />
          <Line
            type="data"
            data={data}
            lineGenerator={lineGenerator}
          />
          {missingLinesData && missingLinesData.map((data, key) => (
            <Line
              key={key}
              idx={key}
              type="missing"
              data={data}
              lineGenerator={lineGenerator}
            />
          ))}
          {upperLineData && (
          <Line
            type="upper-threshold"
            data={upperLineData}
            lineGenerator={lineGenerator}
          />
          )}
          <Line
            type="target"
            data={targetLineData}
            lineGenerator={lineGenerator}
          />
          {lowerLineData && (
          <Line
            type="lower-threshold"
            data={lowerLineData}
            lineGenerator={lineGenerator}
          />
          )}
          {printingStateLineData && (
          <Line
            type="printing"
            data={printingStateLineData}
            lineGenerator={lineGenerator}
          />
          )}
          {coolingStateLineData && (
          <Line
            type="cooling"
            data={coolingStateLineData}
            lineGenerator={lineGenerator}
          />
          )}
          <Line
            type="current"
            data={currentLineData}
            lineGenerator={lineGenerator}
          />
        </g>
      </svg>
    </div>
  );
};

export default Graph;
