import React, {
  useState, useEffect, useRef, Fragment,
} from 'react';
import * as AtlasService from './Atlas.service';
import fire from '../../scripts/firebase';
import './Atlas.scss';
import Logo from '../Login/Logo.jpg';
import MachineSelection from '../../components/MachineSelection/MachineSelection.jsx';
import MachineVisual from '../../components/MachineVisual/MachineVisual.jsx';
import LeftSummary from '../../components/LeftSummary/LeftSummary.jsx';
import RightSummary from '../../components/RightSummary/RightSummary.jsx';
import Camera from '../../components/Camera/Camera.jsx';
import Temperatures from '../../components/Temperatures/Temperatures.jsx';
import Timers from '../../components/Timers/Timers.jsx';
import ErrorLogs from '../../components/ErrorLogs/ErrorLogs.jsx';
import BottomInfo from '../../components/BottomInfo/BottomInfo.jsx';

const DEBUG_MODE = false;

const Atlas = (props) => {
  const [databaseBroken, setDatabaseBroken] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [atlasMachineData, setAtlasMachineData] = useState({});
  const [atlasSensorData, setAtlasSensorData] = useState({});
  const [currentMachine, setCurrentMachine] = useState('');
  const [version, setVersion] = useState('');
  // const [showDebug, setShowDebug] = useState(false);
  const [keyValue, setKeyValue] = useState({});
  // we're using refs here so that our database listeners don't get stale states
  const machineDataRef = useRef();
  const sensorDataRef = useRef();
  machineDataRef.current = atlasMachineData;
  sensorDataRef.current = atlasSensorData;

  const updateCurrentMachine = (machine) => {
    window.history.replaceState(null, '', `?m=${machine}`);
    setCurrentMachine(machine);
  };

  useEffect(() => {
    if (props.isRead) {
      AtlasService.getInitialData({
        setDatabaseBroken,
        setInitialLoad,
        setAtlasMachineData,
        setAtlasSensorData,
        updateCurrentMachine,
      });
    }
  }, [props.isRead]);
  // this useEffect is to trigger when getInitialData is finished
  // attaches the two database listeners
  useEffect(() => {
    if (initialLoad) {
      const env = process.env.REACT_APP_VERSION
        ? process.env.REACT_APP_VERSION.trim()
        : 'dev';
      const dataListener = fire.database().ref('Data/DataLive');
      const buildListener = fire.database().ref('Build');
      const versionReader = fire.database().ref(`versions/${env}`);

      dataListener.on('value', (snapshot) => {
        const data = snapshot.val();
        if (!data) return data;
        updateSensorData(data);
      });

      buildListener.on('value', (snapshot) => {
        const data = snapshot.val();
        if (!data) return;
        updateMachineData(data);
      });

      versionReader.once('value', (snapshot) => {
        const data = snapshot.val();
        if (!data) return;
        setVersion(data);
      });

      if (
        props.machineUrl
        && atlasMachineData.machines.includes(props.machineUrl)
      ) {
        setCurrentMachine(props.machineUrl);
      }
    }
  }, [initialLoad]);
  // used to detect hotkeys
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  }, []);
  // specific hotkey detection
  useEffect(() => {
    if (keyValue.keyValue === 't')
    props.setShowHeader(!props.showHeader);
  }, [keyValue]);
  const handleKeyDown = (e) => {
    setKeyValue({ keyValue: e.key, ctrl: e.ctrlKey });
  };
  const handleKeyUp = () => {
    setKeyValue({ keyValue: null });
  };
  const updateSensorData = (data) => {
    const latestTime = currentSensorStatus[currentMachine].timeStamp;
    const atlasMachineData = machineDataRef.current;

    AtlasService.updateSensorData({
      data,
      atlasMachineData,
      atlasSensorData,
      setAtlasSensorData,
      latestTime,
    });
  };

  const updateMachineData = (data) => {
    const atlasMachineData = machineDataRef.current;
    const atlasSensorData = sensorDataRef.current;

    AtlasService.updateMachineData({
      data,
      currentMachine,
      atlasMachineData,
      setAtlasMachineData,
      updateCurrentMachine,
      atlasSensorData,
      setAtlasSensorData,
      dumpMachineData,
    });
  };
  const dumpMachineData = (machine) => {
    AtlasService.dumpMachineData(machine, atlasSensorData, setAtlasSensorData);
  };

  const {
    machines,
    buildInfoData,
    layerCalibrationData,
    sensorTempRanges,
  } = atlasMachineData;
  if (!machines || !machines.length) {
    return (
      <div className="noData">
        {databaseBroken ? (
          'Something is critically wrong. Unable to find any Build information. Please clear cache and hard refresh, or check the database.'
        ) : (
          <>
            {' '}
            {/* loading screen */}
            <img className="Loading-Logo" src={Logo} alt="Auxetic" />
            <div className="Loading">
              <div className="Loading-Balls" />
              <div className="Loading-Balls" />
              <div className="Loading-Balls" />
            </div>
          </>
        )}
      </div>
    );
  }

  const {
    ambientTemperatureData,
    bedTemperatureData,
    nozzleTemperatureData,
    errorData,
    currentSensorStatus,
    minMaxData,
  } = atlasSensorData;
  const curAmb = currentSensorStatus[currentMachine].ambient;
  const curBed = currentSensorStatus[currentMachine].bed;
  const curNoz = currentSensorStatus[currentMachine].nozzle;
  const { ignoreAmbient } = buildInfoData[currentMachine];
  const isOffline = buildInfoData[currentMachine].machineState === 'Offline';

  const debugMachineSelection = DEBUG_MODE ? [...machines, ...machines, ...machines] : machines;

  return (
    <div className="Atlas">
      <div
        className="Atlas-leftContainer"
      >
        <MachineSelection
          keyValue={keyValue}
          setCurrentMachine={setCurrentMachine}
          machines={debugMachineSelection}
          changeMachine={updateCurrentMachine}
          currentSensorStatus={currentSensorStatus}
          minMaxData={minMaxData}
          ignoreAmbient={ignoreAmbient}
          buildInfoData={buildInfoData}
          currentMachine={currentMachine}
          atlasMachineData={atlasMachineData}
        />
        <MachineVisual
          layerCalibrationData={layerCalibrationData}
          currentSensorStatus={currentSensorStatus[currentMachine]}
          machineState={buildInfoData[currentMachine].machineState}
          ignoreAmbient={ignoreAmbient}
        />
        <div className="visualSpacer" />
        <LeftSummary
          buildData={buildInfoData[currentMachine]}
          keyValue={keyValue.keyValue}
        />
      </div>
      <div className="Atlas-rightContainer">
        <RightSummary buildInfoData={buildInfoData[currentMachine]} />
        <div className="tempsTimersContainer">
          <Temperatures
            ambientTemperatureData={ambientTemperatureData[currentMachine]}
            bedTemperatureData={bedTemperatureData[currentMachine]}
            nozzleTemperatureData={nozzleTemperatureData[currentMachine]}
            curAmb={curAmb}
            curBed={curBed}
            curNoz={curNoz}
            minMaxData={minMaxData[currentMachine]}
            ignoreAmbient={ignoreAmbient}
            sensorTempRanges={sensorTempRanges[currentMachine]}
            machineState={buildInfoData[currentMachine].machineState}
            printingStateTime={buildInfoData[currentMachine].printingStateTime}
            coolingStateTime={buildInfoData[currentMachine].coolingStateTime}
            keyValue={keyValue.keyValue}
          />
          <Timers
            latestTime={currentSensorStatus[currentMachine].timeStamp}
            offline={isOffline}
          />
        </div>
        <div />
        <div className="Atlas-rightContainer-bottom">
          <ErrorLogs
            errors={errorData[currentMachine]}
            offline={isOffline}
            keyValue={keyValue.keyValue}
          />
          <Camera />
        </div>
        <BottomInfo
          // showDebug={showDebug}
          // toggleShowDebug={() => setShowDebug(!showDebug)}
          showHeader={props.showHeader}
          toggleShowHeader={() => props.setShowHeader(!props.showHeader)}
          version={version}
        />
      </div>
    </div>
  );
};

export default Atlas;
