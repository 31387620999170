import React, { useRef, useEffect } from 'react';
import { select } from 'd3-selection';
import { arc } from 'd3-shape';
import { transition } from 'd3-transition';
import { interpolateNumber } from 'd3-interpolate';
import { format } from 'd3-format';
import classnames from 'classnames';
import FrontBar from './front-bar/bar.jsx';
import './ProgressBar.scss';

const formatNumber = format(',d');

const ProgressBar = ({ value, buildData }) => {
  const { machineState } = buildData;
  const ref = useRef(null);

  useEffect(() => {
    renderText(value);
  }, []);

  useEffect(() => {
    renderText(value);
  }, [value]);

  const renderText = (value) => {
    const node = ref.current;
    const t = transition().duration(800);

    select(node).transition(t).tween('text', function () {
      const that = select(this);
      const i = interpolateNumber(that.text().replace(/,/g, ''), value);
      return function (t) { that.text(formatNumber(i(t))); };
    });
  };

  const tau = 2 * Math.PI; // http://tauday.com/tau-manifesto
  const svgWidth = 150;
  const svgHeight = svgWidth;
  const arcWidth = 18;
  const arcOuterRadius = svgWidth / 2;
  const arcInnerRadius = svgWidth / 2 - arcWidth;

  const arcGenerator = arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(5);

  return (
    <div className="percentageArc">
      <svg height={svgHeight} width={svgWidth} className="svg">
        <g className="background-bar-group" transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path d={arcGenerator({ endAngle: tau })} stroke="#18FF00" fill="#18FF00" opacity="0.2" />
        </g>
        <FrontBar
          arcGenerator={arcGenerator}
          value={value}
          tau={tau}
          svgWidth={svgWidth}
          svgHeight={svgHeight}
        />
      </svg>
      <div className={classnames('percentage', { offline: machineState === 'Offline' })} data-testid="progress-bar-percentage">
        <span ref={ref}>0</span>
        <span>%</span>
      </div>
      {/* <div className='percentage'>
        <div>{value}%</div>
      </div> */}
    </div>
  );
};

export default ProgressBar;
