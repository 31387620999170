import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { timeFormatter, timeMath, milToMinSec } from '../../scripts/dateTimeFormatter';
import './Timers.scss';

const Timers = ({ latestTime, offline }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => setCurrentTime(new Date()), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, []);

  const deltaTime = latestTime
    ? milToMinSec(timeMath(currentTime, latestTime, 'subtract'))
    : null;

  return (
    <div className="Timers">
      <div className={classnames('currentTime', 'timer', { offline })} data-testid="current-time">
        Current Time
        {' '}
        <br />
        {' '}
        <div className="timer-val">
          {' '}
          {timeFormatter(currentTime)}
          {' '}
        </div>
      </div>
      <div className={classnames('dataAge', 'timer', { yellow: !deltaTime, offline })} data-testid="data-age">
        Data Age
        {' '}
        <br />
        {' '}
        <div className="timer-val">
          {' '}
          {deltaTime || '--'}
          {' '}
        </div>
      </div>
      <div className={classnames('latestTime', 'timer', { yellow: !latestTime, offline })} data-testid="last-dataset">
        Last Dataset
        {' '}
        <br />
        {' '}
        <div className="timer-val">
          {' '}
          {latestTime ? timeFormatter(latestTime) : '--'}
          {' '}
        </div>
      </div>
    </div>
  );
};

export default Timers;
