import React, { useState, Fragment, useEffect } from 'react';
import $ from 'jquery';
import White from './Machine Icon, White.png';
import Ok from './Machine Icon, Green.png';
import Warn from './Machine Icon, Orange.png';
import Death from './Machine Icon, Red.png';
import Offline from './Machine Icon, Grey.png';
import WarnDot from './History Dot, Orange.png';
import DeathDot from './History Dot, Red.png';
import Pointer from './TooltipArrow.svg';
import Heating from './Machine State, Heating.png';
import Printing from './Machine State, Printing.png';
import Cooling from './Machine State, Cooling.png';
import './MachineSelection.scss';

export const machineMap = (status) => {
  switch (status) {
    case 'ok':
      return Ok;
    case 'warn':
      return Warn;
    case 'death':
      return Death;
    default:
      return White;
  }
};

export const machineStateMap = (state) => {
  switch (state) {
    case 'Heating':
      return Heating;
    case 'Printing':
      return Printing;
    case 'Cooling':
      return Cooling;
    default: return '';
  }
};

const MachineSelection = ({
  changeMachine, machine, currentStatus, pastStatus, machineState, currentMachine,
}) => {
  const [hover, setHover] = useState(false);
  // if someone can do this without jquery, be my guest
  // https://stackoverflow.com/questions/60589000/problem-with-css-hover-tooltips-in-combination-with-overflowscroll-auto-and-po
  useEffect(() => {
    $('.imageContainer').hover(function () {
      const div = $(this).next('div');
      const top = $(this).offset().top + ($(this).height() * 0.3);
      const left = $(this).position().left + $(this).width() + 10;
      div.css('top', `${top}px`);
      div.css('left', `${left}px`);
      div.addClass('visible');
    }, function () {
      $(this).next('div').removeClass('visible');
    });
  }, []);

  const renderIcon = () => {
    switch (machineState) {
      case 'Offline':
        return (
          <img
            className="image"
            src={Offline}
            alt={machine}
          />
        );
      case 'Idle':
        return (
          <>
            <img
              className="image"
              src={White}
              alt={machine}
            />
            {pastStatus !== 'ok'
            && (
            <img
              className="historyDot"
              src={pastStatus === 'death' ? DeathDot : WarnDot}
              alt={pastStatus}
            />
            )}
          </>
        );
      default:
        return (
          <>
            <img
              className="image"
              src={machineMap(currentStatus)}
              alt={machine}
            />
            <img
              className="stateImage"
              src={machineStateMap(machineState)}
              alt={machineState}
            />
            {pastStatus !== 'ok'
            && (
            <img
              className="historyDot"
              src={pastStatus === 'death' ? DeathDot : WarnDot}
              alt={pastStatus}
            />
            )}
          </>
        );
    }
  };

  return (
    <>
      <div
        className={`imageContainer imageContainer-${hover}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => changeMachine(machine)}
        data-testid="Machine-Selector"
      >
        {renderIcon()}
        {currentMachine === machine && (
        <div
          className="currentMachine"
        />
        )}
      </div>

      {/* tooltip */}
      <div className="tooltip">
        <img
          className="pointer"
          alt="<"
          src={Pointer}
        />
        <div className="textContainer">
          <div className="text">
            {machine}
          </div>
        </div>
      </div>
    </>
  );
};

export default MachineSelection;
