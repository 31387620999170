
export const dateTimeMilliFormatter = (dateTime) => {
  const dt = new Date(dateTime);
  const year = dt.getFullYear();
  const month = (`0${dt.getMonth() + 1}`).slice(-2);
  const date = (`0${dt.getDate()}`).slice(-2);

  const string = `${year}-${month}-${date}  ${dt.toLocaleTimeString('en-GB')}.${dt.getMilliseconds()}`;
  return string;
};

export const timeFormatter = (dateTime) => {
  if (typeof dateTime !== 'object') {
    dateTime = new Date(dateTime);
  }
  return dateTime.toLocaleTimeString('en-GB');
};

export const milToMinSec = (millis) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const timeMath = (time1, time2, operation) => {
  if (typeof time1 !== 'number') {
    time1 = time1.getTime();
  }

  if (typeof time2 !== 'number') {
    time2 = time2.getTime();
  }

  switch (operation) {
    case 'add':
      return time1 + time2;
    case 'subtract':
      return time1 - time2;
    default:
      return 'add or subtract?';
  }
};

export const secondsToHourMin = (secs) => {
  const hours = secs / 3600;
  const roundedHours = hours > 0 ? Math.floor(hours) : Math.ceil(hours);
  const formattedHours = roundedHours === 0
    ? hours < 0 ? '-0' : 0
    : roundedHours;
  const remainder = secs %= 3600;
  const minutes = Math.abs(Math.floor(remainder / 60));
  return `${formattedHours} hours ${minutes} minutes`;
};

export const dateTimeFormatter = (dateTime) => {
  const dateOptions = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  };
  const timeOptions = { timeStyle: 'short' };
  return `${dateTime.toLocaleString('en-US', dateOptions)} - ${dateTime.toLocaleString('en-US', timeOptions)}`;
};
