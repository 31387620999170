import React from 'react';
import './FilterButtons.scss';

const FilterButtons = (
  {
    show, filterButtons, filterBy,
  },
) => {
  return (
    <div className={`filterButtonContainer ${show ? 'show' : 'hidden'}`}>
      {filterButtons.map((button) => (
        <div className="buttonRowContainer">
          <div className={`cyanDot ${show && filterBy[button.dotRef] ? 'show' : 'hidden'}`}>•</div>
          <img src={button.button} className="filterButtons" onClick={button.action} />
        </div>
      ))}
    </div>
  );
};
export default FilterButtons;
