import React, { useEffect } from 'react';
import classnames from 'classnames';
import './Dialog.scss';
import { useSwipeable } from 'react-swipeable'

const Dialog = ({
  open, onClose, children, size = 'md', borderColorRed, dialogType = 'temperature',
}) => {
  const containerClasses = classnames('modal-content', `size-${size}`, { borderColorRed });

  const handleKeyUp = (e) => {
    if (e.keyCode === 27) {
      window.removeEventListener('keyup', handleKeyUp, false);
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  }, [handleKeyUp]);

  const handlers = useSwipeable({ onSwiped: ({dir}) => {
    if (dir === 'Up') {
     onClose();
    }
  }});

  return (
    <div {...handlers} className={`modal modal-open-${open}`} data-testid={`${dialogType}-modal`}>
      { open
        ? <div onClick={() => onClose()} className="back-drop" />
        : null}
      <div className={containerClasses}>
        {children}
      </div>
    </div>
  );
};

export default Dialog;
