import React from 'react';
import './App.scss';

import WithAuthentication from './HOC/withAuthentication';

const App = () => (
  <WithAuthentication />
);

export default App;
