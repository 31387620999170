import React, { useRef, useEffect } from 'react';
import { select, selectAll } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';

const Axis = ({
  scale, orient, ticks, transform, tickValues, timeRange,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    renderAxis();
  }, []);

  useEffect(() => {
    updateAxis();
  }, [scale, orient, ticks, tickValues, timeRange]);

  const isXMajorTick = (val) => {
    if (timeRange === 'small') {
      return val % 60 === 0;
    } if (timeRange === 'medium') {
      return val % 600 === 0;
    }
    return val % 3600 === 0;
  };

  const isYMajorTick = (val) => val % 50 === 0;

  const renderAxis = () => {
    const node = ref.current;
    let axis;

    if (orient === 'bottom') {
      axis = axisBottom(scale)
        .tickValues(tickValues)
        .tickFormat((d) => (isXMajorTick(d) ? d : ''));
    }
    if (orient === 'left') {
      axis = axisLeft(scale)
        .tickValues(tickValues)
        .tickFormat((d) => (isYMajorTick(d) ? d : ''));
    }
    select(node).call(axis);
  };

  const updateAxis = () => {
    if (orient === 'bottom') {
      const axis = axisBottom(scale)
        .tickValues(tickValues)
        .tickFormat((d) => (isXMajorTick(d) ? d : ''));
      selectAll(`.${orient}`)
        .call(axis);
    }
    if (orient === 'left') {
      const axis = axisLeft(scale)
        .tickValues(tickValues)
        .tickFormat((d) => (isYMajorTick(d) ? d : ''));
      selectAll(`.${orient}`)
        .call(axis);
    }
  };

  return (
    <g
      ref={ref}
      transform={transform}
      className={`${orient} axis`}
    />
  );
};

export default Axis;
