import React from 'react';
import Axis from './Axis';
import './xy-axis.scss';

const XYAxis = ({
  xScale, yScale, height, width, margins, yTicks, xTickValues, yTickValues, timeRange,
}) => {
  const xSettings = {
    scale: xScale,
    orient: 'bottom',
    transform: `translate(0, ${height})`,
    tickValues: xTickValues,
    timeRange,
  };
  const ySettings = {
    scale: yScale,
    orient: 'left',
    transform: 'translate(0, 0)',
    ticks: yTicks,
    tickValues: yTickValues,
  };
  return (
    <g className="axis-group">
      <Axis {...xSettings} />
      <text
        className="axis-label"
        transform={`translate(${width / 2} ,${height + margins.top})`}
      >
        Build Time (s)
      </text>
      <Axis {...ySettings} />
      <text
        className="axis-label"
        transform="rotate(-90)"
        y={0 - margins.left}
        x={0 - (height / 2)}
        dy="1em"
      >
        Temperature (°C)
      </text>
    </g>
  );
};

export default XYAxis;
