import React from 'react';
import classnames from 'classnames';
import Dialog from '../../uiComponents/Dialog/Dialog.jsx';
import './LeftSummaryDialog.scss';
import { dateTimeFormatter } from '../../scripts/dateTimeFormatter';

export const classNamesGenerator = (dataPoint) => classnames('Dialog-Row', { missing: dataPoint === '???' || dataPoint === '' });

const DialogBox = ({
  handleClose,
  isDialogOpen,
  buildFile,
  buildMaterial,
  buildSpeed,
  buildTime,
  buildVolume,
  buildWeight,
  totalLayers,
  currentLayer,
  estTimeRemaining,
  estCompTime,
  heatingStateTime,
}) => {
  const dataMap = [
    {
      title: 'Build Information',
      classNames: 'Dialog-Header',
    }, {
      title: 'Current Layer',
      data: `${currentLayer} / ${totalLayers}`,
      classNames: classnames('Dialog-Row', { missing: currentLayer === '???' || totalLayers === '???' || currentLayer === '' || totalLayers === '' }),
    }, {
      title: 'Build Start Time',
      data: dateTimeFormatter((new Date(heatingStateTime * 1000))),
      classNames: classNamesGenerator(heatingStateTime),
    }, {
      title: 'Est. Completion Time',
      data: estCompTime,
      classNames: classNamesGenerator(estCompTime),
    }, {
      title: 'Est. Build Time',
      data: buildTime,
      classNames: classNamesGenerator(buildTime),
    }, {
      title: 'Est. Time Remaining',
      data: estTimeRemaining,
      classNames: classNamesGenerator(estTimeRemaining),
    }, {
      classNames: 'divDivider',
    }, {
      title: 'File',
      data: buildFile,
      classNames: classNamesGenerator(buildFile),
    }, {
      title: 'Material',
      data: buildMaterial,
      classNames: classNamesGenerator(buildMaterial),
    }, {
      title: 'Volume',
      data: `${buildVolume} mm³`,
      classNames: classNamesGenerator(buildVolume),
    }, {
      title: 'Weight',
      data: `${buildWeight} grams`,
      classNames: classNamesGenerator(buildWeight),
    }, {
      title: 'Speed',
      data: `${buildSpeed} mm/s`,
      classNames: classNamesGenerator(buildSpeed),
    },
  ];

  return (
    <Dialog onClose={handleClose} open={isDialogOpen} dialogType="left-summary">
      <div className="Dialog">
        {dataMap.map((dataPoint, idx) => (
          <div className={dataPoint.classNames} key={idx}>
            <span>{dataPoint.title}</span>
            <span>{dataPoint.data}</span>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default DialogBox;
