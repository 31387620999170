import React, { useState } from 'react';
import PropTypes from 'prop-types';
import auth from '../../scripts/auth';
import Logo from './Logo.jpg';
import './Login.scss';

const LoginPage = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mess, setMess] = useState('');

  const _handleNoNetwork = () => setMess('Please check your internet connection.');

  const _handleLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await auth.auth().signInWithEmailAndPassword(username, password);
      console.log('Logged in with user:', user);
      const token = await auth.auth().currentUser.getIdTokenResult();
      const claims = token.claims || {};
      props.handleLogin(true, claims);
    } catch (err) {
      if (
        err.code === 'auth/invalid-email'
        || err.code === 'auth/wrong-password'
        || err.code === 'auth/too-many-requests'
      ) {
        _handleInvalid();
      } else if (err.code === 'auth/network-request-failed') {
        _handleNoNetwork();
      } else {
        console.error(err);
      }
    }
  };

  const _handleChange = (setFunction) => (event) => setFunction(event.target.value);

  const _handleInvalid = () => {
    setMess('Login Attempt Failed');
  };

  return (
    <div className="Login">
      <div className="Login-Container">
        <div className="Login-Name"> A U X E T I C </div>
        <img className="Login-Logo" src={Logo} alt="Auxetic" />
        {' '}
        <br />
        <div className="Login-Message" data-testid="Login-Error">
          {' '}
          {mess}
          {' '}
        </div>
        <form className="Login-Form" onSubmit={_handleLogin}>
          <div className="InputContainer">
            <input className="Input" placeholder="USERNAME" onChange={_handleChange(setUsername)} autoFocus data-testid="Username-Input" />
          </div>
          <div className="InputContainer">
            <input className="Input" placeholder="PASSWORD" onChange={_handleChange(setPassword)} type="password" data-testid="Password-Input" />
          </div>
          <button className="Button" type="submit" data-testid="Login-Button">LOGIN</button>
        </form>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};

export default LoginPage;
