import React from 'react';
import Dialog from '../../uiComponents/Dialog/Dialog.jsx';
import './StopDialog.scss';

const DialogBox = ({
  isDialogOpen,
  handleClose,
}) => (
  <Dialog onClose={handleClose} open={isDialogOpen} borderColorRed dialogType="stop">
    <div className="StopDialog">
      <div className="title">
        Emergency Build Cancellation
      </div>
      <div className="paragraph">
        <span className="red">Are you absolutely sure you want to cancel this build?</span>
        <br />
        <br />
        This is
        {' '}
        <span className="red">irreversible</span>
        {' '}
        and may cause
        {' '}
        <span className="red">critical damage</span>
        {' '}
        if the machine is unattended.
        <br />
        <br />
        The entire machine will be rendered
        {' '}
        <span className="red">unusable until serviced.</span>
      </div>
      <div className="buttonRow">
        <div className="stopButton">
          Stop the build
        </div>
        <div className="dontStopButton" onClick={handleClose}>
          Don't stop the build
        </div>
      </div>
    </div>
  </Dialog>
);

export default DialogBox;
