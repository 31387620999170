import React, { Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';
import './Temperatures.scss';
import sensors from '../../constants/sensors';
import cooling from './cooling.png';
import heating from './heating.png';

import Dialog from './TempsDialog';

const Temperatures = ({
  nozzleTemperatureData,
  bedTemperatureData,
  ambientTemperatureData,
  curAmb,
  curBed,
  curNoz,
  minMaxData,
  ignoreAmbient,
  sensorTempRanges = {},
  machineState,
  printingStateTime,
  coolingStateTime,
  keyValue,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTemperature, setSelectedTemperature] = useState(null);

  useEffect(() => {
    let temperatureOn = false;
    const toggleCheck = (currentTemperature) => {
      if (isDialogOpen === true && selectedTemperature === currentTemperature) {
        setIsDialogOpen(false);
      } else {
        setIsDialogOpen(true);
      }
    };
    switch (keyValue) {
      case 'n': {
        temperatureOn = true;
        toggleCheck('nozzle');
        setSelectedTemperature(sensors[0]);
        break;
      }
      case 'b': {
        temperatureOn = true;
        toggleCheck('bed');
        setSelectedTemperature(sensors[1]);
        break;
      }
      case 'a': {
        temperatureOn = true;
        toggleCheck('ambient');
        setSelectedTemperature(sensors[2]);
        break;
      }
      case null: {
        break;
      }
      default:
        temperatureOn = false;
        if (temperatureOn === false) {
          setIsDialogOpen(false);
        }
    }
  }, [keyValue]);

  const dataMap = {
    bed: {
      label: 'Bed Temperature',
      data: bedTemperatureData,
      cur: curBed,
      upperThreshold: sensorTempRanges.bed && sensorTempRanges.bed.highWarn,
      lowerThreshold: sensorTempRanges.bed && sensorTempRanges.bed.lowWarn,
      target: sensorTempRanges.bed && sensorTempRanges.bed.target,
      globalMin: minMaxData.bed.low.value,
      globalMax: minMaxData.bed.high.value,
      printingStateTime, // Refactor to prevent duplicate lines
      coolingStateTime,
    },
    ambient: {
      label: 'Ambient Temperature',
      data: ambientTemperatureData,
      cur: curAmb,
      upperThreshold:
        sensorTempRanges.ambient && sensorTempRanges.ambient.highWarn,
      lowerThreshold:
        sensorTempRanges.ambient && sensorTempRanges.ambient.lowWarn,
      target: sensorTempRanges.ambient && sensorTempRanges.ambient.target,
      globalMin: minMaxData.ambient.low.value,
      globalMax: minMaxData.ambient.high.value,
      printingStateTime,
      coolingStateTime,
    },
    nozzle: {
      label: 'Nozzle Temperature',
      data: nozzleTemperatureData,
      cur: curNoz,
      upperThreshold:
        sensorTempRanges.nozzle && sensorTempRanges.nozzle.highWarn,
      lowerThreshold:
        sensorTempRanges.nozzle && sensorTempRanges.nozzle.lowWarn,
      target: sensorTempRanges.nozzle && sensorTempRanges.nozzle.target,
      globalMin: minMaxData.nozzle.low.value,
      globalMax: minMaxData.nozzle.high.value,
      printingStateTime,
      coolingStateTime,
    },
  };

  const isInactive = (sensor) => sensor === 'ambient' && ignoreAmbient;

  return (
    <>
      {isDialogOpen
        && (
        <Dialog
          isDialogOpen={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          sensorData={dataMap[selectedTemperature]}
        />
        )}
      <div className="Temperatures">
        {sensors.map((sensor) => (
          <div
            className={`Temperatures-TempContainer ${
              isInactive(sensor)
                ? 'Inactive'
                : 'Active'
            }`}
            key={dataMap[sensor].label}
            onClick={() => {
              if (isInactive(sensor)) {
                return;
              }
              setSelectedTemperature(sensor);
              setIsDialogOpen(true);
            }}
            data-testid={`${sensor}-temperature-container`}
          >
            <div className={machineState === 'Offline' ? 'offline' : 'label'} data-testid={`${sensor}-temperature-label`}>
              {dataMap[sensor].label}
            </div>
            <div className="data">
              {/* lowTemp */}
              {machineState !== 'Heating'
              && !isInactive(sensor) && (
              <div
                className={classnames(
                  'smallerTemp',
                  `status-${minMaxData[sensor].low.severity}`,
                  { offline: machineState === 'Offline' || isInactive(sensor) },
                )}
                data-testid={`low-${sensor}-temperature`}
              >
                {minMaxData[sensor].low.value === Infinity
                  ? '--'
                  : minMaxData[sensor].low.value}
              </div>
              )}

              {/* curTemp */}
              <div
                className={classnames(
                  `status-${dataMap[sensor].cur && dataMap[sensor].cur.value && dataMap[sensor].cur.severity}`,
                  { offline: machineState === 'Offline', ignoreAmbient: sensor === 'ambient' && ignoreAmbient },
                )}
                data-testid={`current-${sensor}-temperature`}
              >
                {(dataMap[sensor].cur && dataMap[sensor].cur.value) || '--'}
                {' '}
                °C
                {// target values are currently hardcoded
                machineState === 'Cooling' && !isInactive(sensor)
                  ? (
                    <fragment>
                      <img src={cooling} className="arrow" alt="" />
                      <span>50°C</span>
                    </fragment>
                  )
                  : null
                }
                {machineState === 'Heating' && !isInactive(sensor)
                  ? (
                    <fragment>
                      <img src={heating} className="arrow" alt="" />
                      <span>{dataMap[sensor].target}</span>
                    </fragment>
                  )
                  : null}
              </div>

              {/* highTemp */}
              {machineState !== 'Heating'
              && !isInactive(sensor) && (
              <div
                className={classnames(
                  'smallerTemp',
                  `status-${minMaxData[sensor].low.severity}`,
                  { offline: machineState === 'Offline' || isInactive(sensor) },
                )}
                data-testid={`high-${sensor}-temperature`}
              >
                {minMaxData[sensor].high.value === -Infinity ? '--' : minMaxData[sensor].high.value}
              </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Temperatures;
