import React from 'react';
import './MachineVisual.scss';
// import GreyChassis from './Chassis, Grey.png'
import WhiteChassis from './Chassis, White.png';
import GreyMachine from './Machine, Grey.png';
import GreenBed from './Bed, Green.png';
import OrangeBed from './Bed, Orange.png';
import RedBed from './Bed, Red.png';
// import GreyBed from './Bed, Grey.png'
import WhiteBed from './Bed, White.png';
import GreenNozzle from './Nozzle, Green.png';
import OrangeNozzle from './Nozzle, Orange.png';
import RedNozzle from './Nozzle, Red.png';
// import GreyNozzle from './Nozzle, Grey.png'
import WhiteNozzle from './Nozzle, White.png';
import GreenAmbient from './Ambient, Green.png';
import OrangeAmbient from './Ambient, Orange.png';
import RedAmbient from './Ambient, Red.png';
// import GreyAmbient from './Ambient, Grey.png';
import WhiteAmbient from './Ambient, White.png';
// import YellowMachine from './Machine, Yellow.png';
import YellowBed from './Bed, Yellow.png';
import YellowNozzle from './Nozzle, Yellow.png';
import YellowAmbient from './Ambient, Yellow.png';
import Heating from './State, Heating.png';
import Printing from './State, Printing.png';
import Cooling from './State, Cooling.png';
import sensors from '../../constants/sensors';

// const IDLE = "Idle"

const sensorImages = {
  ambient: {
    warn: OrangeAmbient,
    death: RedAmbient,
    ok: GreenAmbient,
    missing: YellowAmbient,
    // offline: GreyAmbient,
    idle: WhiteAmbient,
  },
  bed: {
    warn: OrangeBed,
    death: RedBed,
    ok: GreenBed,
    missing: YellowBed,
    // offline: GreyBed,
    idle: WhiteBed,
  },
  nozzle: {
    warn: OrangeNozzle,
    death: RedNozzle,
    ok: GreenNozzle,
    missing: YellowNozzle,
    // offline: GreyNozzle,
    idle: WhiteNozzle,
  },
};

export const stateImages = (machineState) => {
  switch (machineState) {
    case 'Heating':
      return <img src={Heating} className="MachineVisual-Visual onTop" alt={machineState} data-testid="heating-img" />;
    case 'Printing':
      return <img src={Printing} className="MachineVisual-Visual onTop" alt={machineState} data-testid="printing-img" />;
    case 'Cooling':
      return <img src={Cooling} className="MachineVisual-Visual onTop" alt={machineState} data-testid="cooling-img" />;
    default:
      return null;
  }
};

const MachineVisual = ({
  currentSensorStatus, machineState, ignoreAmbient,
}) => {
  if (machineState === 'Offline') {
    return (
      <div className="MachineVisual">
        <img src={GreyMachine} className="MachineVisual-Visual" alt="" data-testid="offline-img" />
      </div>
    );
  }

  return (
    <div className="MachineVisual">
      <img src={WhiteChassis} className="MachineVisual-Visual" alt="" />
      {sensors.map((sensor) => {
        if (machineState === 'Idle') {
          return <img src={sensorImages[sensor].idle} className="MachineVisual-Visual onTop" alt={`${sensor} invalid`} key={sensor} data-testid={`${sensor}-idle`} />;
        }
        if (ignoreAmbient && sensor === 'ambient') {
          return <img src={sensorImages[sensor].idle} className="MachineVisual-Visual onTop" alt={`${sensor} invalid`} key={sensor} data-testid="ambient-idle" />;
        }
        if (!currentSensorStatus[sensor] || !currentSensorStatus[sensor].value) {
          return <img src={sensorImages[sensor].missing} className="MachineVisual-Visual onTop" alt={`${sensor} invalid`} key={sensor} data-testid={`${sensor}-missing`} />;
        }

        return <img src={sensorImages[sensor][currentSensorStatus[sensor].severity]} className="MachineVisual-Visual onTop" alt={`${sensor} ${currentSensorStatus[sensor].severity}`} key={sensor} data-testid={`${sensor}-${currentSensorStatus[sensor].severity}`} />;
      })}
      {stateImages(machineState)}
    </div>
  );
};

export default MachineVisual;
