import React from 'react';
import HeaderLogo from './HeaderLogo.png';
import './Header.scss';

const header = ({ showHeader }) => (
  <div className={`Header-${showHeader}`}>
    <img src={HeaderLogo} alt="Auxetic" className="Header-Logo" />
    <button href="https://orders.auxetic.co/" className="Header-Button" color="inherit"> ORDERS </button>
    <button href="https://inventory.auxetic.co/" className="Header-Button" color="inherit"> INVENTORY </button>
    <button href="https://hotlinks.auxetic.co/" className="Header-Button" color="inherit"> HOTLINKS </button>
    <button href="https://atlas.auxetic.co" className="Header-Button" color="inherit"> ATLAS </button>
  </div>
);

export default header;
