import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyBV7jhDWKDtroDSLNTZLOnd4MBUtwi-hrE',
  authDomain: 'auxauth-25fa5.firebaseapp.com',
  projectId: 'auxauth-25fa5',
  messagingSenderId: '20913534098',
};
const auth = firebase.initializeApp(config, 'auth');

export default auth;
