import React, { useState } from 'react';

import auth from '../scripts/auth';
import LoginPage from '../pages/Login/Login';
import Header from '../components/Header/Header';
import Atlas from '../pages/Atlas/Atlas';

const WithAuthentication = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [claims, setClaims] = useState({});
  const [showHeader, setShowHeader] = useState(false);
  let machineUrl;

  // auto login after refresh
  auth.auth().onAuthStateChanged(async () => {
    if (!authenticated && auth.auth() && auth.auth().currentUser) {
      const token = await auth.auth().currentUser.getIdTokenResult();
      const claims = token.claims || {};
      setAuthenticated(true);
      setClaims(claims);
    }
  });

  // puts machine in address bar
  const { search } = window.location;
  if (search) {
    const urlParams = new URLSearchParams(search);
    machineUrl = urlParams.get('m');
  }

  return !authenticated
    ? (
      <LoginPage
        handleLogin={(authenticated, claims) => {
          setAuthenticated(authenticated);
          setClaims(claims);
        }}
      />
    )
    : (
      <div className="App">
        <Header showHeader={showHeader} />
        <div className="topSpacer" />
        <Atlas
          showHeader={showHeader}
          setShowHeader={setShowHeader}
          isRead={claims.atlasRead}
          machineUrl={machineUrl}
        />
        <div className="bottomSpacer" />
      </div>
    );
};

export default WithAuthentication;
