import React from 'react';
import Dialog from '../../uiComponents/Dialog/Dialog.jsx';

import { dateTimeMilliFormatter } from '../../scripts/dateTimeFormatter';

import './ErrorLogsDialog.scss';

const sensorMap = {
  nozzle: 'Nozzle',
  bed: 'Bed',
  ambient: 'Ambient',
};

const rangeMap = {
  low: 'LOW',
  high: 'HIGH',
};

const DialogBox = ({
  isDialogOpen,
  handleClose,
  errors,
}) => (
  <Dialog onClose={handleClose} open={isDialogOpen} size="tlsk" dialogType="error-logs">
    <div className="LogsDialog">
      <div className="LogsDialog-Top">
        <div className="LogsDialog-Top-Header">
          Error Log
        </div>
        <div>
          (
          {errors && errors.length}
          )
        </div>
      </div>
      <div className="divDivider" />
      {errors
        ? errors.map((error, idx) => (
          <div key={idx} className="errorLine">
            {`${dateTimeMilliFormatter(error.timeStamp)} - ${sensorMap[error.sensor]} Temperature ${rangeMap[error.range]}`}
            {' '}
            <span className={`value ${error.severity}`}>
              {' '}
              {error.value}
              {' '}
            </span>
          </div>
        ))
        : 'No Errors'}
    </div>
  </Dialog>
);

export default DialogBox;
