import fire from '../../scripts/firebase';
import sensors from '../../constants/sensors';

const sensorsMap = {
  ambient: 'aT',
  bed: 'bT',
  nozzle: 'nT',
};

export const _fixTimeStamp = (ts) => (ts ? Number(ts) * 1000 : '');

export const _checkError = (curr, lowWarn, lowDeath, highWarn, highDeath) => {
  if (curr > highDeath || curr < lowDeath) {
    return { severity: 'death', range: curr > highDeath ? 'high' : 'low' };
  } if (curr > highWarn || curr < lowWarn) {
    return { severity: 'warn', range: curr > highWarn ? 'high' : 'low' };
  }
  return false;
};

// refactor with sensors.js
export const minMaxDataTemplate = () => ({
  nozzle: {
    low: { value: Infinity, severity: 'ok' },
    high: { value: -Infinity, severity: 'ok' },
  },
  bed: {
    low: { value: Infinity, severity: 'ok' },
    high: { value: -Infinity, severity: 'ok' },
  },
  ambient: {
    low: { value: Infinity, severity: 'ok' },
    high: { value: -Infinity, severity: 'ok' },
  },
});

const printingStates = {
  Heating: true,
  Printing: true,
  Cooling: true,
};

export const isActivelyPrinting = (state) => state === 'Printing';

// gets initial auxatlas on application load
export const getInitialData = async ({
  setDatabaseBroken,
  setInitialLoad,
  setAtlasMachineData,
  setAtlasSensorData,
  updateCurrentMachine,
}) => {
  const machinePayload = await fire.database().ref('Build').once('value');
  const machineData = machinePayload.val();

  if (!machineData.buildInformation || !Object.keys(machineData.buildInformation).length) {
    return setDatabaseBroken(true);
  }

  // list of names of the machines
  const machines = Object.keys(machineData.buildInformation);

  const buildInfoData = {};
  const layerCalibrationData = {};
  const materialData = {};
  // data history loading
  const ambientTemperatureData = {}; // aT
  const bedTemperatureData = {}; // bT
  const nozzleTemperatureData = {}; // nT
  const errorData = {};
  // duplication of the latest data in temperature data sets
  const currentSensorStatus = {};
  const sensorTempRanges = {};
  const minMaxData = {};

  // mapping of variable names to the object it belongs
  const dataMap = {
    ambient: ambientTemperatureData,
    bed: bedTemperatureData,
    nozzle: nozzleTemperatureData,
  };

  // get buildInfo, layerCalibration, materialData
  machines.forEach((machine) => {
    buildInfoData[machine] = machineData.buildInformation[machine];
    layerCalibrationData[machine] = (machineData.layerCalibration && machineData.layerCalibration[machine]) || null;
    materialData[machine] = (machineData.material && machineData.material[machine]) || null;
  });

  // calculate sensorTempRanges
  if (machineData.material) {
    for (const machine of machines) {
      sensorTempRanges[machine] = {};
      for (const sensor of sensors) {
        if (sensor === 'ambient' && machineData.buildInformation[machine].ignoreAmbient) {
          sensorTempRanges[machine][sensor] = null;
        } else if (machineData.material[machine]
          && !isNaN(machineData.material[machine][`${sensor}TemperatureTol`])
          && !isNaN(machineData.material[machine][`${sensor}TemperatureMax`])
          && !isNaN(machineData.material[machine][`${sensor}TemperatureMin`])
          && !isNaN(machineData.material[machine][`${sensor}Temperature`])
        ) {
          const tol = machineData.material[machine][`${sensor}TemperatureTol`];
          sensorTempRanges[machine][sensor] = {
            lowWarn: machineData.material[machine][`${sensor}TemperatureMin`],
            highWarn: machineData.material[machine][`${sensor}TemperatureMax`],
            lowDeath: machineData.material[machine][`${sensor}TemperatureMin`] - tol,
            highDeath: machineData.material[machine][`${sensor}TemperatureMax`] + tol,
            target: machineData.material[machine][`${sensor}Temperature`],
          };
        }
      }
    }
  }

  const sensorPayload = await fire.database().ref('Data/DataHistory').once('value');
  if (!sensorPayload) {
    return setDatabaseBroken(true);
  }
  const sensorData = sensorPayload.val();

  machines.forEach((machine) => {
    ambientTemperatureData[machine] = [];
    bedTemperatureData[machine] = [];
    nozzleTemperatureData[machine] = [];
    errorData[machine] = [];
    currentSensorStatus[machine] = {};
    minMaxData[machine] = { ...minMaxDataTemplate() };
  });

  // for each machine
  machines.forEach((machine) => {
    // if there's firebase data for that machine
    if (sensorData[machine]) {
      // for each data point in that machine
      Object.values(sensorData[machine]).forEach((dataPoint, dataPointIdx) => {
        const timeStamp = _fixTimeStamp(dataPoint.t);

        // for each sensor in that data point
        for (const sensor of sensors) {
          if (!(sensor === 'ambient' && buildInfoData[machine].ignoreAmbient)) {
            const curr = dataPoint[sensorsMap[sensor]];
            // push it to that sensorData
            dataMap[sensor][machine].push({
              value: curr,
              timeStamp,
            });
            // we know if it's actually printing if
            if (
              // printStateTime exists and dataPoint time is after printStateTime
              buildInfoData[machine].printingStateTime && dataPoint.t > buildInfoData[machine].printingStateTime
              // if coolingStateTime exists, dataPoint time is before coolingStateTime
              && (buildInfoData[machine].coolingStateTime ? dataPoint.t < buildInfoData[machine].coolingStateTime : true)
            ) {
              // update lowest temp value
              if (curr < minMaxData[machine][sensor].low.value) {
                minMaxData[machine][sensor].low.value = curr;
              }

              // update highest temp value
              if (curr > minMaxData[machine][sensor].high.value) {
                minMaxData[machine][sensor].high.value = curr;
              }
              let errorObj;
              // set currentSensorStatus timestamp
              // TODO: this sets the time stamp 3 times
              // stop setting this so damn much, only set once at the end
              currentSensorStatus[machine].timeStamp = timeStamp;
              // set initial currentSensorStatus
              // changes if errorObj exists
              currentSensorStatus[machine][sensor] = {
                value: curr,
                severity: 'ok',
              };

              // if the temperature ranges are calculated via material data
              if (sensorTempRanges[machine] && sensorTempRanges[machine][sensor]) {
                // check if there's an error
                errorObj = _checkError(
                  curr,
                  sensorTempRanges[machine][sensor].lowWarn,
                  sensorTempRanges[machine][sensor].lowDeath,
                  sensorTempRanges[machine][sensor].highWarn,
                  sensorTempRanges[machine][sensor].highDeath,
                );
                if (errorObj) {
                  const { severity, range } = errorObj;
                  // push the error
                  errorData[machine].push({
                    timeStamp,
                    sensor,
                    range,
                    severity,
                    value: curr,
                  });
                  // update lowest temp severity
                  if (range === 'low') {
                    if (minMaxData[machine][sensor].low.severity !== 'death') {
                      minMaxData[machine][sensor].low.severity = severity === 'death'
                        ? 'death'
                        : 'warn';
                    }
                  } else {
                    // update highest temp severity
                    if (minMaxData[machine][sensor].high.severity !== 'death') {
                      // don't set if it's warn over and over again
                      minMaxData[machine][sensor].high.severity = severity === 'death'
                        ? 'death'
                        : 'warn';
                    }
                  }
                }
                // if it's the latest (current) data set calculate currentSensorStatus
                if (dataPointIdx === Object.keys(sensorData[machine]).length - 1) {
                  // if error exists, update severity
                  if (errorObj) {
                    currentSensorStatus[machine][sensor] = {
                      value: curr,
                      severity: errorObj.severity,
                    };
                  }
                }
              }
            }
          }
        }
      });
    }
  });

  updateCurrentMachine(machines[0]);
  setAtlasSensorData({
    ambientTemperatureData,
    bedTemperatureData,
    nozzleTemperatureData,
    errorData,
    currentSensorStatus,
    minMaxData,
  });
  setAtlasMachineData({
    machines,
    buildInfoData,
    layerCalibrationData,
    materialData,
    sensorTempRanges,
  });
  setInitialLoad(true);
};

// gets called when firebase Data/DataLive gets updated
export const updateSensorData = async ({
  data, atlasMachineData, atlasSensorData, setAtlasSensorData, latestTime,
}) => {
  const timeStamp = _fixTimeStamp(data.t);
  const { machines, sensorTempRanges, buildInfoData } = atlasMachineData;
  // this check exists to make sure data in DataLive is not already in our data history
  if (timeStamp <= latestTime) {
    return;
  }
  const ambientTemperatureData = { ...atlasSensorData.ambientTemperatureData };
  const bedTemperatureData = { ...atlasSensorData.bedTemperatureData };
  const nozzleTemperatureData = { ...atlasSensorData.nozzleTemperatureData };
  const currentSensorStatus = { ...atlasSensorData.currentSensorStatus };
  const errorData = { ...atlasSensorData.errorData };
  // these should be deep cloned?
  const minMaxData = { ...atlasSensorData.minMaxData };

  const dataMap = {
    ambient: ambientTemperatureData,
    bed: bedTemperatureData,
    nozzle: nozzleTemperatureData,
  };
  // for each machine
  machines.forEach((machine) => {
    // if new dataset contains that machine
    if (data[machine]) {
      // reset currentSensorStatus
      currentSensorStatus[machine] = { timeStamp };
      // for each sensor
      for (const sensor of sensors) {
        if (!(sensor === 'ambient' && buildInfoData[machine].ignoreAmbient)) {
          const curr = data[machine][sensorsMap[sensor]];
          // default currentSenorStatus
          currentSensorStatus[machine][sensor] = { value: curr, severity: 'ok' };
          // push new data if printer is heating/printing/cooling
          if (printingStates[buildInfoData[machine].machineState]) {
            dataMap[sensor][machine].push({
              value: curr,
              timeStamp,
            });
            // if we have sensorTempRanges
            if (sensorTempRanges[machine] && sensorTempRanges[machine][sensor]) {
              // calculate error
              const errorObj = _checkError(
                curr,
                sensorTempRanges[machine][sensor].lowWarn,
                sensorTempRanges[machine][sensor].lowDeath,
                sensorTempRanges[machine][sensor].highWarn,
                sensorTempRanges[machine][sensor].highDeath,
              );
              // if there's an error
              if (errorObj) {
                const { severity, range } = errorObj;
                // update current severity
                currentSensorStatus[machine][sensor].severity = errorObj && errorObj.severity;
                // push new error if printer is printing
                if (isActivelyPrinting(buildInfoData[machine].machineState)) {
                  errorData[machine].push({
                    timeStamp,
                    sensor,
                    range,
                    severity,
                    value: curr,
                  });

                  // update lowest temp severity
                  if (range === 'low') {
                    if (minMaxData[machine][sensor].low.severity !== 'death') {
                      // don't set warn so many times
                      minMaxData[machine][sensor].low.severity = severity === 'death'
                        ? 'death'
                        : 'warn';
                    }
                  } else {
                    // update highest temp severity
                    if (minMaxData[machine][sensor].high.severity !== 'death') {
                      minMaxData[machine][sensor].high.severity = severity === 'death'
                        ? 'death'
                        : 'warn';
                    }
                  }
                }
              }
            }

            // if printing, update min/max
            if (isActivelyPrinting(buildInfoData[machine].machineState)) {
              // update lowest temp value
              if (curr < minMaxData[machine][sensor].low.value) {
                minMaxData[machine][sensor].low.value = curr;
              }
              // update highest temp valuez
              if (curr > minMaxData[machine][sensor].high.value) {
                minMaxData[machine][sensor].high.value = curr;
              }
            }
          }
        }
      }
    }
  });

  setAtlasSensorData({
    ambientTemperatureData,
    bedTemperatureData,
    nozzleTemperatureData,
    currentSensorStatus,
    errorData,
    minMaxData,
  });
};

// gets called when firebase Build table gets updated
export const updateMachineData = async ({
  data,
  atlasMachineData,
  setAtlasMachineData,
  currentMachine,
  updateCurrentMachine,
  dumpMachineData,
}) => {
  const buildInfoData = { ...atlasMachineData.buildInfoData };
  const layerCalibrationData = { ...atlasMachineData.layerCalibrationData };
  const materialData = { ...atlasMachineData.materialData };
  const sensorTempRanges = { ...atlasMachineData.sensorTempRanges };
  let machines = [...atlasMachineData.machines];

  machines.forEach((machine) => {
    // refactor this removal of removed machines
    if (!(Object.keys(data.buildInformation).includes(machine))) {
      delete buildInfoData[machine];
      delete layerCalibrationData[machine];
      delete materialData[machine];
      delete sensorTempRanges[machine];

      // TODO: delete removed machine data from atlasSensorData by
      // const atlasSensorData = {...atlasSensorData}
      // ???
      // profit
      // setAtlasSensorData(atlasSensorData)
    }
  });

  machines = Object.keys(data.buildInformation);

  machines.forEach((machineName) => {
    if (buildInfoData[machineName].heatingStateTime !== data.buildInformation[machineName].heatingStateTime) {
      dumpMachineData(machineName);
    }

    buildInfoData[machineName] = data.buildInformation[machineName];
    layerCalibrationData[machineName] = data.layerCalibration && data.layerCalibration[machineName];
    materialData[machineName] = data.material && data.material[machineName];
    if (data.material) {
      sensorTempRanges[machineName] = {};
      for (const sensor of sensors) {
        if (data.material[machineName]
          && !isNaN(data.material[machineName][`${sensor}TemperatureTol`])
          && !isNaN(data.material[machineName][`${sensor}TemperatureMax`])
          && !isNaN(data.material[machineName][`${sensor}TemperatureMin`])
        ) {
          const tol = data.material[machineName][`${sensor}TemperatureTol`];
          sensorTempRanges[machineName][sensor] = {
            lowWarn: data.material[machineName][`${sensor}TemperatureMin`],
            highWarn: data.material[machineName][`${sensor}TemperatureMax`],
            lowDeath: data.material[machineName][`${sensor}TemperatureMin`] - tol,
            highDeath: data.material[machineName][`${sensor}TemperatureMax`] + tol,
            target: data.material[machineName][`${sensor}Temperature`],
          };
        }
      }
    }
  });

  setAtlasMachineData({
    machines,
    buildInfoData,
    layerCalibrationData,
    materialData,
    sensorTempRanges,
  });

  // if currentMachine no longer exists, default to first machine
  if (!machines.includes(currentMachine)) {
    updateCurrentMachine(machines[0]);
  }
};

export const dumpMachineData = (machine, atlasSensorData, setAtlasSensorData) => {
  const sensorData = { ...atlasSensorData };
  sensorData.ambientTemperatureData[machine] = [];
  sensorData.bedTemperatureData[machine] = [];
  sensorData.nozzleTemperatureData[machine] = [];
  sensorData.errorData[machine] = [];
  sensorData.currentSensorStatus[machine] = [];
  sensorData.minMaxData[machine] = { ...minMaxDataTemplate() };
  setAtlasSensorData(sensorData);
};
