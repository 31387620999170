import React, { useState, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import './LeftSummary.scss';
import {
  secondsToHourMin,
  timeMath,
  dateTimeFormatter,
} from '../../scripts/dateTimeFormatter';

import Dialog from './LeftSummaryDialog.jsx';

const LeftSummary = ({ buildData, keyValue }) => {
  const {
    buildFile = '???',
    buildMaterial = '???',
    buildSpeed = '???',
    buildTime = '???',
    buildVolume = '???',
    buildWeight = '???',
    totalLayers = '???',
    heatingStateTime = null,
    completionTime = null,
    machineState,
  } = buildData;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // temp hard code
  const currentLayer = '???';

  const formattedBuildTime = secondsToHourMin(buildTime);

  const runTime = heatingStateTime
    && timeMath(
      completionTime
        ? Math.floor(completionTime)
        : Math.floor(new Date().getTime() / 1000),
      Math.floor(heatingStateTime),
      'subtract',
    );
  const formattedRunTime = runTime ? secondsToHourMin(runTime) : '???';

  const estCompTime = heatingStateTime && buildTime && heatingStateTime * 1000 + buildTime * 1000;
  const formattedEstCompTime = estCompTime && dateTimeFormatter(new Date(estCompTime));

  const estTimeRemaining = buildTime && runTime && timeMath(buildTime, runTime, 'subtract');
  const formattedEstTimeRemaining = estTimeRemaining
    ? secondsToHourMin(estTimeRemaining)
    : '???';
  useEffect(() => {
    if (keyValue === 'i') {
      setIsDialogOpen(!isDialogOpen);
    } else if (keyValue !== null) {
      setIsDialogOpen(false);
    }
  }, [keyValue]);

  return (
    <>
      <Dialog
        isDialogOpen={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        buildFile={buildFile}
        buildMaterial={buildMaterial}
        buildSpeed={buildSpeed}
        buildTime={formattedBuildTime}
        buildVolume={buildVolume}
        buildWeight={buildWeight}
        totalLayers={totalLayers}
        currentLayer={currentLayer}
        estTimeRemaining={formattedEstTimeRemaining}
        estCompTime={formattedEstCompTime}
        heatingStateTime={heatingStateTime}
      />
      <div
        className="LeftSummary"
        onClick={() => {
          setIsDialogOpen(true);
        }}
        data-testid="left-summary-container"
      >
        <div
          className={classnames('LeftSummary-Row', {
            missing:
              currentLayer === '???'
              || totalLayers === '???'
              || currentLayer === ''
              || totalLayers === '',
            offline: machineState === 'Offline',
          })}
          data-testid="current-layer"
        >
          <span>Current Layer </span>
          <span>
            {currentLayer}
            {' '}
            /
            {totalLayers === '' ? '-' : totalLayers}
          </span>
        </div>
        <div className={classnames('LeftSummary-Row', { missing: formattedRunTime === '???', offline: machineState === 'Offline' })} data-testid="run-time">
          <span>Run Time</span>
          <span>{formattedRunTime}</span>
        </div>
        <div className={classnames('LeftSummary-Row', { missing: formattedEstTimeRemaining === '???', offline: machineState === 'Offline' })} data-testid="est-time-remaining">
          <span>Est. Time Remaining</span>
          <span>{formattedEstTimeRemaining}</span>
        </div>
      </div>
    </>
  );
};

export default LeftSummary;
