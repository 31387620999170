import React from 'react';
import classnames from 'classnames';
import './BottomInfo.scss';

import HideHeader from './Hidden.png';
import ShowHeader from './Visible.png';
// import BugSVG from './Bug.svg';

const BottomInfo = ({
  showHeader, toggleShowHeader, version,
  // showDebug, toggleShowDebug,
}) => {
  const hideHeaderImgClasses = classnames('headerToggle', { 'headerToggle-hidden': showHeader });
  // const debugToggleClasses = classnames('debugToggle', { 'debugToggle-hidden': showDebug });

  return (
    <div className="bottomInfo">
      <img
        onClick={() => toggleShowHeader()}
        className={hideHeaderImgClasses}
        src={!showHeader ? HideHeader : ShowHeader}
      />
      <div className="version">
        v
        {version}
      </div>
      {/* <img
        onClick={() => toggleShowDebug()}
        className={debugToggleClasses}
        src={BugSVG}
      /> */}
    </div>
  );
};

export default BottomInfo;
