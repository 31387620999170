import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyAhkwnHRPPkke6aNKEkxRutUA-OY8yG96o',
  authDomain: 'auxatlas.firebaseapp.com',
  databaseURL: 'https://auxatlas.firebaseio.com',
  projectId: 'auxatlas',
  storageBucket: 'auxatlas.appspot.com',
  messagingSenderId: '224804557119',
  appId: '1:224804557119:web:751867f15840b507',
};
const fire = firebase.initializeApp(config);

export default fire;
