import React from 'react';
import Dialog from '../../uiComponents/Dialog/Dialog.jsx';
import './CameraDialog.scss';

const DialogBox = ({
  isDialogOpen,
  handleClose,
}) => (
  <Dialog onClose={handleClose} open={isDialogOpen}>
    <div>Live feed will live here</div>
  </Dialog>
);

export default DialogBox;
