import React from 'react';
import useDimensions from 'react-use-dimensions';
import Dialog from '../../uiComponents/Dialog/Dialog.jsx';
import './TempsDialog.scss';
import Graph from './Graph/Graph';

const DialogBox = ({
  isDialogOpen,
  handleClose,
  sensorData = {},
}) => {
  const [ref, { width, height }] = useDimensions();

  let convertedData; let
    earliestTimeStamp;
  const missingPoints = [];

  if (sensorData.data && sensorData.data.length) {
    earliestTimeStamp = sensorData.data[0].timeStamp;

    // TODO refactor with .map?
    convertedData = sensorData.data.reduce((result, d) => {
      if (d.value !== undefined && d.timeStamp !== undefined) {
        result.push({
          value: d.value,
          timeStamp: (d.timeStamp - earliestTimeStamp) / 1000,
        });
      }

      return result;
    }, []);

    for (const dataPoint of sensorData.data) {
      if (!dataPoint.value) {
        missingPoints.push({
          timeStamp: (dataPoint.timeStamp - earliestTimeStamp) / 1000,
        });
      }
    }
  }

  const convertedPrintingStateTime = sensorData.printingStateTime && (sensorData.printingStateTime - earliestTimeStamp / 1000);
  const convertedCoolingStateTime = sensorData.coolingStateTime && (sensorData.coolingStateTime - earliestTimeStamp / 1000);
  const worthRendering = sensorData.data && sensorData.data.length && !isNaN(width) && !isNaN(height) && width && height;

  return (
    <Dialog onClose={handleClose} open={isDialogOpen} size="lg">
      <div className="TempsDialog" ref={ref}>
        {worthRendering && (
        <Graph
          data={convertedData}
          missingData={missingPoints}
          title={sensorData.label}
          upperThreshold={sensorData.upperThreshold}
          lowerThreshold={sensorData.lowerThreshold}
          globalMin={sensorData.globalMin}
          globalMax={sensorData.globalMax}
          target={sensorData.target}
          printingStateTime={convertedPrintingStateTime}
          coolingStateTime={convertedCoolingStateTime}
          parentWidth={width}
          parentHeight={height}
        />
        )}
        {/* <pre><code>{JSON.stringify(sensorData, null, 4)}</code></pre> */}
      </div>
    </Dialog>
  );
};

export default DialogBox;
