import React, { Fragment, useState, useEffect } from 'react';
import './ErrorLogs.scss';

import Dialog from './ErrorLogsDialog';
import { dateTimeMilliFormatter } from '../../scripts/dateTimeFormatter';

const Logs = ({ errors, offline, keyValue }) => {
  const sensorMap = {
    nozzle: 'Nozzle',
    bed: 'Bed',
    ambient: 'Ambient',
  };

  const rangeMap = {
    low: 'LOW',
    high: 'HIGH',
  };

  const errorsBrief = [];
  for (let i = 0; i < 8; i++) {
    errorsBrief.push(
      <div key={i} className="errorBrief">
        {errors && errors[i] ? (
          <>
            {`${dateTimeMilliFormatter(errors[i].timeStamp)} - ${
              sensorMap[errors[i].sensor]
            } Temperature ${rangeMap[errors[i].range]}`}

            <span className={`value ${errors[i].severity}`}>

              {` ${errors[i].value}`}

            </span>
          </>
        ) : (
          ''
        )}
      </div>,
    );
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  useEffect(() => {
    if (keyValue === 'e') {
      setIsDialogOpen(!isDialogOpen);
    } else if (keyValue !== null) {
      setIsDialogOpen(false);
    }
  }, [keyValue]);
  return (
    <>
      <Dialog
        isDialogOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        errors={errors}
      />
      <div className="Logs" onClick={() => setIsDialogOpen(true)} data-testid="error-logs-container">
        <div className={offline ? 'ErrorLogs offline' : 'ErrorLogs'} data-testid="error-logs">
          <div className="ErrorLogs-Top">
            <div className="ErrorLogs-Top-Header">Error Log</div>
            <div>
              {errors && errors.length > 9 && `(+${errors.length - 9})`}
            </div>
          </div>
          <div className={`divDivider offline-${offline}`} />
          <div className="errorsList">{errorsBrief}</div>
        </div>
      </div>
    </>
  );
};

export default Logs;
